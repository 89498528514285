/* .navbarcontainer{
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: right;
} */

.bg-light {
    --bs-bg-opacity: 1;
    background-color: #fff !important;
}
.email{
    cursor: auto;
    list-style: none;
    padding: 0.5rem;
    color: black;
}