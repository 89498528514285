*{
  font-family: 'Montserrat', sans-serif;
}
.head {
  /* font-family: "Times New Roman", Times, serif; */
  display: flex;
  gap: 11%;
  align-items: center;
  width: 100%;
}
.image-Div {
  padding: 1rem;
  cursor: pointer;
}
.profile-Div {
  color: #5da2d5;
  margin: 13px;
}
.download-Div {
  float: right;
  right: 7%;
  position: absolute;
}
.dropbtn {
  background-color: transparent;
  color: #5da2d5;
  padding: 5px;
  font-size: 36px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  right: 0;
  display: none;
  position: absolute;
  cursor: pointer;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 6;
}
.bottom-dropDown {
  display: none;
  position: absolute;
  left: -105px;
  top: -216%;
  cursor: pointer;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 6;
}
.bottom-dropDown a {
  color: black;
  padding: 12px 16px;
  font-size: medium;
  text-decoration: none;
  display: block;
  z-index: 10;
}
.bottom-dropDown a:hover {
  background-color: #ddd;
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  font-size: medium;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown:hover .bottom-dropDown {
  display: block;
}
.dropdown:hover .dropbtn {
  background-color: transparent;
}

.homeImgDiv {
  display: contents;
}
.homeSearchBox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 71%;
}
div#root {
  height: 100vh;
}
.mainHome {
  /* background-image: url("https://static.rdc.moveaws.com/images/hero/default/2021-11/webp/hp-hero-desktop-xl.webp");
  background-repeat: no-repeat, repeat; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 222px);
}
.pac-container {
  /* background-color: #3e7dad; */
  background-color: #5da2d5;
  font-weight: 700;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, 
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;*/
}
.pac-item,
.pac-item-query {
  color: #f7f7f7;
}
.pac-item:hover {
  background: #6666;
}

.container-space {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.align-items-center {
  align-items: center;
  width: 100%;
}
.floating-icon {
  z-index: 3;
  position: fixed;
  bottom: 55px;
  right: 20px;
}
.disable {
  display: none;
}
.quote{
  /* font-family: 'circular'; */
  color: #C1272D;
  font-weight: bold;
  padding: 1rem;
  font-size: 1.5rem;
}

