.carousel-item-next,
.carousel-item-start {
  display: none;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  color: black;
  background-image: none;
  text-decoration: none;
}


.carousel-control-prev-icon::before {
  /* font-family: "Font Awesome 5 Free"; */
  content: "\f053";
  text-decoration: none;
}

.carousel-control-next-icon::before {
  /* font-family: "Font Awesome 5 Free"; */
  content: "\f054";
  text-decoration: none;
}