.modal.show .modal-dialog {
  transform: none;
  min-width: fit-content;
}
.modal-body {
  display: flex;
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}
.roomCount {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40%;
  font-weight: 600;
  /* font-family: sans-serif; */
}
.padsplitImage {
  height: 200px;
  width: 250px;
}