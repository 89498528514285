/* img {
  width: 300px;
  padding-block: 1rem;
} */

input {
  height: 38px;
}

.table-container {
    overflow: scroll;
}

.table-container thead tr:nth-child(3) > th:not(:first-child) {
  white-space: nowrap;
}

.table-container tbody tr td:first-child {
  font-weight: bold;
}
/* .container1 {
   background-color: white; 
} */
.section1 {
  display: inline-block;
  vertical-align: top;
  width: 49%;
}
a{
  color: black;
}
