.main-div {
  display: flex;
  gap: 10px;
}
.li-item {
  list-style: none;
  padding-top: 8px;
  padding-left: 13px;
  padding-right: 13px;
  background: #fff;
  margin-top: 0.5rem;
}
.left-displayImage {
  padding-top: 8px;
  padding-left: 13px;
  padding-right: 13px;
}
.li-item-right {
  list-style: none;
  padding-top: 8px;
  padding-left: 13px;
  padding-right: 13px;
  background: #fff;
  margin-top: 0.5rem;
}
.right-data-container {
  border: 1px solid #d5dfff;
  width: 50vh;
  /* font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif; */
  box-shadow: 3px -11px 26px #07051c0a;
  background-color: #efeffe;
  position: relative;
  height: fit-content;
}
.left-data-container {
  /* font-family: sans-serif; */
  font-weight: 600;
  border: 1px solid #d5dfff;
  margin-bottom: 5px;
}
.left-container {
  position: sticky;
  left: 0;
  z-index: 3;
  opacity: 0.1px;
  height: -moz-fit-content;
  height: fit-content;
  background: #efeffe;
  width: 52.8vh;
}

.right-container {
  display: flex;
  gap: 1rem;
  margin-left: 20px;
  text-align: center;
  overflow-y: scroll;
  overflow-x: scroll;
  height: 97vh;
}
.height180 {
  height: 257px;
  display: flex;
  position: sticky;
  top: 0;
  background-color: #efeffe;
}
.mapHeight {
  height: 14.5rem;
}
.left-td {
  text-align: start;
}
.right-td {
  text-align: end;
}
td {
  padding-right: 10px;
  padding-left: 10px;
}
.li-item-type-1 {
  list-style: none;
  font-weight: 600;
  /* font-family: sans-serif; */
  position: sticky;
  top: 0px;
  padding-top: 0.5rem;
  color: #fff;
  background-color: #3e7dad;
}
.li-item-type-2 {
  list-style: none;
  background-color: #3e7dad;
  font-weight: 600;
  /* font-family: sans-serif; */
  position: sticky;
  top: 0;
  font-size: 15px;
  color: #fff;
}
.li-item-type-2:hover {
  color: white;
  opacity: 1;
}
a {
  color: #0400ff;
}
img.property {
  position: sticky;
  top: 24px;
  height: 232px;
  background-color: #efeffe;
  width: 50vh;
}
.notFound {
  position: sticky;
  top: 23px;
  width: 300px;
  padding-bottom: 37px;
  padding-top: 26px;
  background-color: #efeffe;
}
.mapNotFound {
  width: 300px;
  padding-top: 26px;
  padding-bottom: 8px;
  background-color: #efeffe;
}
.comPropMap {
  height: 201px;
  width: 297px;
  padding-top: 26px;
  padding-bottom: 8px;
  background-color: #efeffe;
}
.affordableHousing {
  height: 14rem;
  align-items: center;
  display: flex;
}
.af-housing {
  padding-top: 2rem;
}
#scroll-bar ::-webkit-scrollbar {
  display: block;
  width: 10em;
  overflow: auto;
  height: 2em;
}
#scroll-bar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}
#scroll-bar ::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 10px;
}
.copy:hover {
  color: black;
  opacity: 1;
}
.copy {
  cursor: pointer;
  margin-right: 1rem;
  color: grey;
}
.copyProperty:hover {
  color: lightblue;
}
.copyProperty {
  cursor: pointer;
  margin-right: 1rem;
}
.close {
  opacity: 0.4;
  position: absolute;
  top: 0px;
  right: 5px;
  cursor: pointer;
}
.close:hover {
  color: black;
  opacity: 1;
}
hr {
  margin: 1rem 0;
  position: sticky;
  top: 291px;
  color: inherit;
  border: 0;
  border-top: 1px solid;
}

.partition {
  border: 0.5px solid #d5dfff;
  margin-bottom: 8px;
  margin-inline: 5%;
  position: sticky;
  top: 277px;
  background-color: #c1c1c1;
}

.btn {
  display: inline-block;
  border: 1px solid #00b4cc;
  background: #00b4cc;
  color: #fff;
  border-radius: 0 5px 5px 0;
  padding: 9px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  user-select: none;
  background-image: none;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 107px;
}
.loading {
  position: fixed;
  background: grey;
  opacity: 0.5;
  top: 0;
  width: 100vw;
  z-index: 9;
  min-height: 100vh;
}
.search-container-header {
  position: relative;
  width: 100%;
}
.search-input-header {
  background: #fff;
  border: 2px solid #5da2d5;
  border-radius: 8px;
  font-size: 18px;
  left: 24px;
  min-width: 60%;
  outline: none;
  padding: 8px 12px;
  position: relative;
}
.button-38 {
  background-color: #20c5a0;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 0.5rem 1rem;
  text-align: center;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  cursor: pointer;
  border: none;
}
.button-38:hover {
  background-color: #20c5a0;
}
.button-38:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.button-38:focus-visible {
  box-shadow: none;
}
.download-button {
  background-color: #20c5a0;
  color: #fff;
  font-size: 14px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  font-weight: 600;
  padding: 0.5rem 1rem;
  text-align: center;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  cursor: pointer;
  border: none;
}

#dropdown-id {
  background-color: #20c5a0;
  color: #fff;
  font-size: 14px;
  height: 37px;
  font-weight: 600;
  padding: 0.5rem 1rem;
  text-align: center;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  cursor: pointer;
  border: none;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}
.tabDiv {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-right: 2rem;
  /* border-radius: 12px; */
  cursor: pointer;
}
.propertyBasicInfo {
  padding-top: 0rem;
}
.propertyTabs {
  padding-top: 2.4rem;
}
.openTab {
  background-color: #3e7dad;
  color: #fff;
}
.linkA {
  color: blue;
}
.maptab {
  padding-top: 2.6rem;
}
input.search-input-header::placeholder {
  text-align: center;
}
.tabDiv:hover {
  background-color: lightblue;
  color: black;
}
.allTabs {
  list-style: none;
  padding-top: 8px;
  padding-left: 13px;
  padding-right: 13px;
}
.checkHover {
  background-color: lightblue;
}
.logo {
  width: 30%;
  padding: 10px;
}
.image-Div img.logo {
  width: 18rem;
}
.hud-data {
  width: -webkit-fill-available;
  margin-top: 1.5rem;
}
.hudTab {
  height: 11rem;
  align-items: center;
  display: flex;
}
.collapsed-Div {
  background-color: #3e7dad;
  color: #fff;
}
.toggle-text {
  position: relative;
  bottom: 10px;
}
.toggle {
  padding: 4px;
}
.carouselPropertyImage {
  width: 50%;
}
.badge {
  position: absolute;
  left: 0;
  background: #ea4335;
  border-radius: 0 0 13px 0;
  font-size: 14px;
  padding-right: 0.8rem;
  top: 0;
}
.right-container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.right-container::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: lightgray;
}
.header-sec {
  position: sticky;
  top: 0;
}
.iframe-sec {
  width: fit-content;
  height: 16rem;
}
.heading-ellipsis {
  white-space: nowrap;
  width: 49.8vh;
  overflow: hidden;
  text-overflow: ellipsis;
}
