.bottom-div {
  display: flex;
  gap: 10px;
  margin-top: 1.5%;
  margin-left: 1rem;
}
.affordable-Div {
  opacity: 0.1px;
  border-radius: 12px;
  background: #efeffe;
  min-width: 75.5%;
}
.flexable-Div {
  display: flex;
}
.li-Div {
  width: 50%;
}
.bottomAllTabs {
  list-style: none;
  padding-top: 8px;
  padding-left: 13px;
}
.noData {
  text-align: center;
  padding-block: 9%;
}
.info-icon{
  cursor: pointer;
}