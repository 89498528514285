.data-container {
  display: flex;
  margin-left: 1rem;
  text-align: left;
  overflow-y: auto;
  overflow-x: scroll;
  height: 100%;
}
.left-data {
  display: flex;
  position: sticky;
  min-width: fit-content;
  position: sticky;
  left: 0;
  z-index: 3;
}
.right-data {
  display: flex;
  min-width: fit-content;
}
.li-headings {
  list-style: none;
  font-weight: 600;
  /* font-family: sans-serif; */
  position: sticky;
  text-align: center;
  top: 0px;
  padding: 0.5rem;
  color: #fff;
  background-color: #3e7dad;
}

.popup {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  background: grey;
  opacity: 0.5;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popupInner {
  width: 55px;
  height: 55px;
  /* margin-left: 13%; */
  /* top: 19rem; */
  /* left: 36%; */
  border: 3px solid #f3f3f3;
  border-top: 3px solid #20c5a0;
  border-radius: 36px;
  animation: spinner 1s linear infinite;
  position: absolute;
  z-index: 99;
}
.padsplit-heading {
  list-style: none;
  font-weight: 600;
  /* font-family: sans-serif; */
  padding: 0.5rem;
}
.padsplit-div {
  margin-top: 1rem;
  margin-left: 1rem;
  background-color: #3e7dad;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-right: 2rem;
  cursor: pointer;
  border: 0.5px solid #d5dfff;
  box-shadow: -8px 16px 32px #0d09351f;
}
.padsplit-div:hover {
  background-color: lightblue;
  color: black;
}
.imageIcon {
  cursor: pointer;
}
.roomNotFound {
  padding-block: 4.5rem;
  background: #fff;
}
.pad-data-container {
  border: 1px solid #d5dfff;
  min-width: fit-content;
  /* font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif; */
  box-shadow: 3px -11px 26px #07051c0a;
  background-color: #efeffe;
  position: relative;
  height: fit-content;
}
.nullPadData {
  /* font-family: sans-serif; */
  font-weight: 600;
  text-align: center;
  height: 25vh;
  align-items: center;
  display: grid;
  margin-left: 1rem;
  background: #efeffe;
}
