.timingsDiv {
  /* gap: 16px; */
  width: 50%;
  /* display: grid; */
}
.timing-heading {
  font-weight: 600;
  /* font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif; */
  gap: 16px;
  display: flex;
  align-items: center;
  line-height: 32px;
  font-size: 20px;
}
.timing {
  gap: 16px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, auto);
}
.days {
  gap: 8px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.roomsHeadings {
  list-style: none;
  font-weight: 600;
  font-size: 16px;
}
.dialog-modal {
  justify-content: center;
  align-items: center;
  display: flex;
}
.divFlex {
  display: flex;
}
.roomImage {
  width: 41vh;
  height: 30vh;
}
.descriptionCard {
  border: 1px solid #d5dfff;
  /* font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif; */
  box-shadow: 3px -11px 26px #07051c0a;
  padding-left: 1rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-radius: 8px 8px 8px 8px;
}
.roomImgDiv {
  margin-top: auto;
  margin-bottom: auto;
}
.topCard {
  gap: 0.5rem;
  display: flex;
}
.description {
  overflow-y: auto;
    overflow-x: hidden;
    /* width: 57.6vh; */
    height: 30vh;
    margin-left: 0.5rem;
}
.vertical-line {
  border-left: 2px solid #d5dfff;
  padding-right: 6px;
}
.roomDetailsTable {
  overflow-x: scroll;
  overflow-y: hidden;
  min-height: 31vh;
}
.modal-header {
  display: flex;
  flex-shrink: 0;
  padding: 1vh;
  align-items: center;
  justify-content: space-between;
  /* padding: var(--bs-modal-header-padding); */
  border-bottom: var(--bs-modal-header-border-width) solid
    var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: min-content;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
  min-height: 85vh;
}
.owner-timings{
  width: 75vh;
  display: flex;
}