@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 55px;
  height: 55px;
  /* margin-left: 13%; */
  /* top: 19rem; */
  /* left: 36%; */
  border: 3px solid #f3f3f3;
  border-top: 3px solid #20c5a0;
  border-radius: 36px;
  animation: spinner 1s linear infinite;
  position: absolute;
  z-index: 99;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
}

.bottomDiv {
  position: relative;
  bottom: -150px;
  left: 309px;
}
