.Ref-links {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 13rem;
}
.ref-container {
  max-height: 73vh;
  display: flex;
  margin-left: 1rem;
  text-align: left;
  overflow-y: auto;
  overflow-x: scroll;
}
