.divGaps {
  padding-top: 39px;
}
.displayImageHeight {
  height: 224px;
  display: flex;
  /* position: sticky; */
  top: 0;
  background-color: #efeffe;
}
.noRooms{
    padding-block: 12.8rem;
    background: #fff;
    margin-top: 0.5rem;
}
