.search {
  width: 100%;
  position: relative;
  display: flex;
}

.searchTerm {
  width: 100%;
  border: 3px solid #00b4cc;
  border-right: none;
  padding: 5px;
  height: 36px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9dbfaf;
}

.searchTerm:focus {
  color: #00b4cc;
}

.searchButton {
  width: 230px;
  height: 36px;
  display: flex;
  border: 1px solid #00b4cc;
  background: #00b4cc;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

.wrap {
  width: 372px;
}
.search {
  padding-top: 1.3rem;
}
.searchIcon {
  cursor: pointer;
  font-size: 23px;
  position: relative;
  right: 11px;
  top: 2px;
}

.bottom-search {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}
.loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  background: grey;
  opacity: 0.5;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
